@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../public/assets/fonts/open-sans-v40-latin-300.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../public/assets/fonts/open-sans-v40-latin-regular.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../public/assets/fonts/open-sans-v40-latin-600.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../public/assets/fonts/open-sans-v40-latin-700.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  src: url("../public/assets/fonts/bebas-neue-v14-latin-regular.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("../public/assets/fonts/roboto-v30-latin-100.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  src: url("../public/assets/fonts/roboto-v30-latin-100italic.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../public/assets/fonts/roboto-v30-latin-300.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: url("../public/assets/fonts/roboto-v30-latin-300italic.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../public/assets/fonts/roboto-v30-latin-regular.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("../public/assets/fonts/roboto-v30-latin-italic.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../public/assets/fonts/roboto-v30-latin-500.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("../public/assets/fonts/roboto-v30-latin-500italic.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../public/assets/fonts/roboto-v30-latin-700.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("../public/assets/fonts/roboto-v30-latin-700italic.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../public/assets/fonts/roboto-v30-latin-900.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  src: url("../public/assets/fonts/roboto-v30-latin-900italic.woff2")
    format("woff2");
}
